<template>
  <div>
    <div class="lawContent" v-if="lawDetail">
      <div class="title">{{ lawDetail.lawName }}</div>
      <div class="time">{{ lawDetail.createTime }}</div>
      <div class="content ql-editor" v-html="lawDetail.lawDesc"></div>
    </div>
  </div>
</template>

<script>
import { getLawData } from "@/api/common";
export default {
  name: "lawDetail",
  data() {
    return {
      lawDetail: null
    }
  },
  mounted() {
    this.getLawData(this.$route.query.type);
  },
  methods: {
    getLawData(type) {
      getLawData(type).then((data) => {
        this.lawDetail = data;
      })
    }
  },
}
</script>

<style lang="less" scoped>
.lawContent {
  width: 733px;
  margin: 0 auto;
  margin-top: 90px;
  .title {
    font-size: 24px;
    font-weight: 600;
    color: #122545;
    line-height: 33.6px;
    margin-bottom: 16px;
  }
  .time {
    font-size: 12px;
    line-height: 16.8px;
    color: #637381;
  }
  .content {
    margin-top: 20px;
  }
}
</style>